<template>
  <div class="FantasyNews">
    <div class="head">
      <img
        src="https://thcdn.gggamedownload.com/source/mobileAssets/2wap_03.jpg"
        alt=""
      >
    </div>
    <div class="body">
      <div class="swiper">
        <van-swipe :autoplay="3000" @change="onChange">
          <van-swipe-item v-for="(item, index) in swipeList" :key="index">
            <img :src="item.img_path" @click="handleGoto(item)">
          </van-swipe-item>
          <template #indicator>
            <div class="custom-indicator fenye">
              <div class="fenye_warp">
                <div
                  v-for="(item, index) in swipeList"
                  :key="index"
                  :class="{ active: activeIndex === index }"
                  class="fenye_item"
                />
              </div>
            </div>
          </template>
        </van-swipe>
      </div>

      <div class="news">
        <div class="type">
          <div class="more" @click="gotoNewsList">MORE+</div>
          <van-tabs
            v-model="activeType"
            type="card"
            swipeable
            animated
            color="#4A5F6D"
          >
            <van-tab
              v-for="item in titleList"
              :key="item.id"
              :name="item.type"
              :title="item.title"
            >
              <List :tag="item.type" />
            </van-tab>
          </van-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const swiper1 = 'https://thcdn.gggamedownload.com/source/assets/5-1.jpg'
const swiper2 = 'https://thcdn.gggamedownload.com/source/assets/shiting.jpg'
const swiper3 = 'https://thcdn.gggamedownload.com/source/assets/Z.jpg'
const swiper4 = require('@/assets/swiper/1.jpg')
import { __getSwiperList } from '@/api/imgInfo.js'

import List from './List'

export default {
  name: 'FantasyNews',
  components: { List },
  data() {
    return {
      swipeList: [],
      titleList: [
        {
          id: 1,
          title: '最新',
          type: null
        },
        {
          id: 2,
          title: '新闻',
          type: 'news'
        },
        {
          id: 3,
          title: '公告',
          type: 'announcement'
        },
        {
          id: 4,
          title: '活动',
          type: 'gameWalkthrough'
        },
        {
          id: 5,
          title: '见闻纪',
          type: 'gameStrategy'
        }
      ],

      activeIndex: 0,
      activeType: ''
    }
  },
  created() {
    this.getCarouselList()
  },

  methods: {
    async getCarouselList() {
      const res = await __getSwiperList()
      if (res.code === 200) {
        this.swipeList = res.data
      }
    },
    onChange(index) {
      this.activeIndex = index
    },

    gotoNewsList(e) {
      this.$router.push('/newsList')
    },
    clickNews({ id }) {
      const scrollTop = document.documentElement.scrollTop
      this.$router.push({
        path: '/newsDetails',
        query: {
          id,
          y: scrollTop
        }
      })
    },
    handleGoto(item) {
      if (item.img_to_path) {
        window.open(item.img_to_path)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .van-tabs__wrap {
    .van-tabs__nav {
      .van-tab {
        // &:last-child {
        //   border: 1px solid transparent;
        //   background-color: #000;
        // }
      }
    }
  }
  .van-tabs__content {
    min-height: 290px;
    overflow: hidden;
  }
}

.FantasyNews {
  width: 100%;
  background: url('https://thcdn.gggamedownload.com/source/mobileAssets/2wap_04.jpg')
    center;
  .head {
    img {
      width: 100%;
    }
  }
  .body {
    margin-top: 23px;
    width: 100%;
    .swiper {
      width: 100%;
      padding: 0 22px;
      img {
        width: 100%;
      }
      .fenye {
        width: 100%;
        height: 15px;
        margin: 7px 0;
        display: flex;
        justify-content: center;
        .fenye_warp {
          display: flex;
          justify-content: center;
          align-items: center;
          .fenye_item {
            width: 8px;
            height: 8px;
            background-color: #4a5f6d;
            margin: 0 6px;
            transform: rotateZ(45deg);
            &.active {
              background-color: #acbfcd;
              width: 10px;
              height: 10px;
            }
          }
        }
      }
      .van-swipe-item {
        width: 100%;
        overflow: hidden;
      }
    }

    .news {
      margin-top: 15px;
      .type {
        // height: ;
        .ul {
          padding: 0 28px;
          padding-top: 16px;
          .li {
            width: 100%;
            display: flex;
            .left {
              width: 60px;
              min-width: 60px;
              border-right: 1px solid #d7d9da;
              padding-bottom: 33px;

              .month {
                font-size: 18px;
                color: #5c6371;
              }
              .year {
                font-size: 12px;
                color: #788fa1;
              }
            }
            .right {
              flex: 1;
              margin-left: 12px;
              .title {
                font-size: 14px;
                width: 240px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                .title_type {
                  border-right: 1px solid #d49d77;
                  color: #d49d77;
                  padding: 0 5px;
                }
                .title_text {
                  padding-left: 5px;
                  color: #5b6475;
                }
              }
              .content {
                margin-top: 5px;
                font-size: 12px;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                color: #898d95;
              }
            }
          }
        }
        .more {
          color: #5b6475;
          float: right;
          margin-top: 15px;
          font-size: 13px;
          padding-right: 5px;
          clear: both;
          position: relative;
          z-index: 999;
        }
      }
    }
  }
}
</style>
