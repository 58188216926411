<template>
  <div class="banner">
    <div class="body">
      <div class="head">
        <Head />
      </div>
      <div class="bg">
        <img src="@/assets/change/newImg9.jpg" alt="">
      </div>
      <div class="spin" @click="openVideo">
        <div class="z" />
      </div>
      <div class="footer">
        <img src="@/assets/change/newImg11.png" alt="" @click="clickReserve">
      </div>
    </div>
    <PlayVideo v-if="showPlayVideo" ref="PlayVideo" :video-data="videoData" />
    <MobileReserveModels ref="MobileReserveModels" />
  </div>
</template>

<script>
import Head from "@/mobileViews/components/Head";
import device from "@/utils/GetDeviceInformation";
import MobileReserveModels from "@/components/MobileReserveModels";
import PlayVideo from "../../../components/PlayVideo";
import { reqGetLinks } from "@/api/news.js"
import { getLinksParams } from "@/utils/getDownloadLinks.js"
export default {
  name: "Banner",
  components: { Head, MobileReserveModels, PlayVideo },
  data() {
    return {
      videoData: {
        src: "https://thcdn.gggamedownload.com/apk/lwop.mp4"
      },
      showPlayVideo: false,
      lostworld_ios: "",
      lostworld_android: "",
      lostworld_pc: ""
    };
  },
  created() {
    this.getDownloadLinks()
  },
  methods: {
    clickDownLoad(url, type) {
      const link = document.createElement('a');
      if (type === 'lostworld_android') {
        const ourkey = 'RILvS9HYMOsiFptQ75dgW38V6Bh2Jyw';
        const time = Date.parse(new Date()) / 1000;
        const uri = url.split("com")[1]; // 取com后面的所有值
        const key = this.$md5(`${uri}${ourkey}${time}`); // 转 md5 密钥计算参数

        link.href = url + `?key=${key}&time=${time}`; // 设置下载链接
      } else {
        link.href = url; // 设置下载链接
      }
      link.download = url.substring(url.lastIndexOf("/") + 1); // 设置下载文件的名称 取最后一个/后面的内容为下载名字
      // 将 <a> 标签添加到 DOM 中
      document.body.appendChild(link);
      // 触发点击事件
      link.click();
      // 删除添加的 <a> 标签
      document.body.removeChild(link);
    },
    clickReserve() {
      if (device === 'ios') {
        this.clickDownLoad(this.lostworld_ios)
      } else {
        this.clickDownLoad(this.lostworld_android, 'lostworld_android')
      }
    },
    openVideo() {
      this.showPlayVideo = true;
    },
    async getDownloadLinks() {
      const res = await reqGetLinks(getLinksParams)

      res.data.forEach(item => {
        this[item.keywords] = item.data
      })
      // console.log(res)
      // console.log(res["data"])
      // res["data"].forEach(element => {
      //   // console.log(element)
      //   if (element["keywords"] === "lostworld_ios") {
      //     this.iosLink = element["data"]
      //   } else if (element["keywords"] === "lostworld_android") {
      //     this.andoridLink = element["data"]
      //   } else if (element["keywords"] === "lostworld_pc") {
      //     this.pcLink = element["data"]
      //   }
      // });
      // console.log(this.iosLink)
      // console.log(this.andoridLink)
      // console.log(this.pcLink)
    }
  }
};
</script>

<style lang="scss" scoped>
@-webkit-keyframes changeright {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes changeright {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@keyframes changeright {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.banner {
  padding-top: 59px;
  position: relative;
  background-color: #191d26;
  .bg {
    img {
      width: 100%;
    }
  }
  .spin {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
    .z {
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      &::after{
        content: '';
        width: 65.5px;
        height: 65.5px;
        background: url('https://thcdn.gggamedownload.com/source/mobileAssets/131.png') no-repeat center;
        background-size: 100%;
      }
      &::before{
        content: '';
        position: absolute;
        width: 89.5px;
        height: 89.5px;
        background: url('https://thcdn.gggamedownload.com/source/mobileAssets/130.png') no-repeat center;
        background-size: 100%;
        animation: changeright 5s linear infinite;
      }
    }
  }
  .footer {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 39px;
    text-align: center;
    img {
      width: 70%;
      margin: 0 auto;
    }
  }
}
</style>
