<template>
  <div class="Ph_home">
    <Banner />
    <FantasyNews />
    <CharacterIntroduction />
    <!-- <AudiovisualFeast /> -->
    <FootSwiper />
    <Footer />
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Banner from './models/Banner'
import FantasyNews from './models/FantasyNews'
import CharacterIntroduction from './models/CharacterIntroduction'
import AudiovisualFeast from './models/AudiovisualFeast'
import FootSwiper from './models/FootSwiper'
import Footer from '@/components/MobileFloor'
export default {
  name: 'PhHome',
  components: {
    Banner,
    FantasyNews,
    CharacterIntroduction,
    AudiovisualFeast,
    Footer,
    FootSwiper
  },
  mounted() {
    this.$nextTick(() => {
      const top = this.$route.query.y
      if (top) {
        document.documentElement.scrollTop = top;
        document.body.scrollTop = top;
      }
    })
  }
}
</script>

<style>

</style>
