<template>
  <div class="AudiovisualFeast">
    <div class="head">
      <img src="https://thcdn.gggamedownload.com/source/mobileAssets/2wap_07.jpg">
    </div>
    <div class="body">
      <div class="top">
        <div class="center">
          <div v-for="item in VideoList" :key="item.id" class="left" @click="openVideo(item)">
            <img src="https://thcdn.gggamedownload.com/source/mobileAssets/z143.png" alt="">
          </div>

        </div>
      </div>
      <div class="bottom" @click="$router.push('/VideoList')">
        更多内容
      </div>
    </div>
    <PlayVideo v-if="showPlayVideo" ref="PlayVideo" :video-data="videoData" />
  </div>
</template>

<script>

import PlayVideo from '../../../components/PlayVideo'
export default {
  name: 'AudiovisualFeast',
  components: { PlayVideo },
  data() {
    return {
      showPlayVideo: false,
      videoData: {
        src: ''
      },
      VideoList: [
        {
          id: 1,
          src: 'https://thcdn.gggamedownload.com/source/MV/1-MV-2.mp4'
        },
        {
          id: 2,
          src: 'https://thcdn.gggamedownload.com/source/MV/2-MV-1.mp4'
        }
      ]
    }
  },
  methods: {
    openVideo({ src }) {
      this.videoData.src = src
      this.showPlayVideo = true
    }
  }

}
</script>
<style lang="scss" scoped>

.AudiovisualFeast {

  width: 100%;
  .head {
    img {
      width: 100%;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: url('https://thcdn.gggamedownload.com/source/mobileAssets/Efuda (71).png') no-repeat center;
    background-size: 100% 100%;
    .top {
      flex: 1;
      .center {
        height: 100%;
        max-width: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 26px 16px;
      img {
        width: 100%;
        height: 100%;
      }
        .left {
          position: relative;
          flex: 1;
          height: 100%;
          border: 1px solid #000;
          margin-right: 7px;
          &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 105%;
            height: 105%;
            background-color: rgba(0,0,0,.5);
          }
          &::before {
            content: '';
            position: absolute;
            background: url('https://thcdn.gggamedownload.com/source/mobileAssets/y1.png') no-repeat center;
            z-index: 99;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 105%;
            height: 105%;
            background-color: rgba(0,0,0,.5);
          }
        }

      }
    }
    .bottom {
      height: 46px;
      line-height: 46px;
      text-align: center;
      color: #93A4B1;
      font-size: 28px;
      background-color: rgba(0,0,0,.5);
    }
  }
}
</style>
