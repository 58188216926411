<template>
  <div class="CharacterIntroduction">
    <div class="head">
      <img src="https://thcdn.gggamedownload.com/source/mobileAssets/2wap_05.jpg">
    </div>
    <div class="body">
      <v-touch height="500px" :swipe-options="{direction: 'horizontal'}" @swipeleft="swipeLeft" @swiperight="swipeRight">

        <div class="renwu">
          <img :src="activeData.showImgPath">
          <div class="name">
            <img :src="activeData.name">
          </div>
        </div>

        <div class="info" @click="clickNext">
          <span class="Previous" />
          <span class="next" />
          <div class="ul">
            <div v-if="activeData.race" class="li"><span class="key">种族</span><span class="value">{{ activeData.race }}</span></div>
            <div v-if="activeData.ability" class="li"><span class="key">能力</span><span class="value">{{ activeData.ability }}</span></div>
            <div v-if="activeData.alias" class="li"><span class="key">别名</span><span class="value">{{ activeData.alias }}</span></div>
            <div v-if="activeData.profession" class="li"><span class="key">职业</span><span class="value">{{ activeData.profession }}</span></div>
            <div v-if="activeData.risk" class="li"><span class="key">危险度</span><span class="value">{{ activeData.risk }}</span></div>
            <div v-if="activeData.friendly" class="li"><span class="key">人类友好度</span><span class="value">{{ activeData.friendly }}</span></div>
            <div v-if="activeData.location" class="li"><span class="key">主要活动地点</span><span class="value">{{ activeData.location }}</span></div>
            <div v-if="activeData.residence" class="li"><span class="key">住所</span><span class="value">{{ activeData.residence }}</span></div>
          </div>
          <div class="fenye">
            <span class="big">{{ activeData.id | filterCount }}/</span>
            <span>09</span>
          </div>
        </div>
      </v-touch>

      <div class="avatar">
        <div class="centerBox">
          <div v-for="(item, index) in assetsList" :key="item.id" class="box">
            <img v-if="item.id===activeData.id" :src="item.path" @click.self="clickRole(item,index)">
            <img v-else :src="item.maskPath" @click.self="clickRole(item,index)">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const avatar_boli = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/246.png'
const avatar_wuyu = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/245.png'
const avatar_baziyun = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/244.png'
const avatar_hunqian = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/243.png'
const avatar_ailisi = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/242.png'
const avatar_lingxian = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/241.png'
const avatar_leimiliya = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/240.png'
const avatar_paqiuli = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/239.png'
const avatar_shiliuye = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/238.png'

const maskAvatar_boli = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/146.png'
const maskAvatar_wuyu = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/145.png'
const maskAvatar_baziyun = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/144.png'
const maskAvatar_hunqian = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/143.png'
const maskAvatar_ailisi = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/142.png'
const maskAvatar_lingxian = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/141.png'
const maskAvatar_leimiliya = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/140.png'
const maskAvatar_paqiuli = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/139.png'
const maskAvatar_shiliuye = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/138.png'

const Details_boli = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/11.png'
const Details_wuyu = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/12.png'
const Details_baziyun = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/13.png'
const Details_hunqian = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/14.png'
const Details_ailisi = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/15.png'
const Details_lingxian = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/16.png'
const Details_leimiliya = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/17.png'
const Details_paqiuli = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/18.png'
const Details_shiliuye = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/19.png'

const name_boli = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/1.png'
const name_wuyu = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/2.png'
const name_baziyun = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/3.png'
const name_hunqian = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/4.png'
const name_ailisi = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/5.png'
const name_lingxian = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/6.png'
const name_leimiliya = require('@/mobileAssets/7.png')
const name_paqiuli = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/8.png'
const name_shiliuye = 'https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/9.png'
export default {
  name: 'CharacterIntroduction',
  filters: {
    filterCount(val) {
      const count = val <= 9 ? '0' + val : val
      return count
    }
  },
  data() {
    return {
      assetsList: [
        {
          id: 4,
          path: avatar_hunqian,
          showMask: true,
          maskPath: maskAvatar_hunqian,
          showImgPath: Details_hunqian,
          name: name_hunqian,
          race: '半人半灵',
          ability: '使用剑术程度的能力',
          alias: '“幽人的园艺师”、“生命的二刀流”、“半人半灵的半吊子”',
          profession: '',
          residence: '',
          title: '魂魄妖梦',
          JPtitle: 'こんぱく ようむ',
          risk: '中',
          friendly: '高',
          location: '冥界'
        },
        {
          id: 8,
          path: avatar_paqiuli,
          showMask: true,
          maskPath: maskAvatar_paqiuli,
          showImgPath: Details_paqiuli,
          name: name_paqiuli,
          race: '魔法使',
          ability: '操纵火＋水＋木＋金＋土＋日＋月的程度的能力',
          alias: '“知识与避世的少女”、“不动的大图书馆”、“不明的魔法之元”',
          profession: '',
          residence: '',
          title: '帕秋莉·诺蕾姬',
          JPtitle: 'パチュリー・ノーレッジ',
          risk: '中',
          friendly: '普通',
          location: '红魔馆'
        },
        {
          id: 7,
          path: avatar_leimiliya,
          showMask: true,
          maskPath: maskAvatar_leimiliya,
          showImgPath: Details_leimiliya,
          name: name_leimiliya,
          race: '吸血鬼',
          ability: '操纵命运程度的能力',
          alias: '“永远鲜红的幼月”、“红色恶魔”、“深红的夜行性恶魔”',
          profession: '',
          residence: '',
          title: '蕾米莉亚·斯卡雷特',
          JPtitle: 'レミリア・スカーレット',
          risk: '极高',
          friendly: '极低',
          location: '红魔馆附近'

        },
        {
          id: 1,
          path: avatar_boli,
          showMask: true,
          maskPath: maskAvatar_boli,
          showImgPath: Details_boli,
          name: name_boli,
          race: '人类',
          ability: '在空中飞行程度的能力',
          alias: '“博丽神社的巫女小姐”、“永远的巫女”、 ”乐园的可爱巫女“',
          profession: '巫女',
          residence: '博丽神社',
          title: '博丽灵梦',
          JPtitle: 'はくれい れいむ',
          risk: '',
          friendly: '',
          location: ''

        },
        {
          id: 2,
          path: avatar_wuyu,
          showMask: true,
          maskPath: maskAvatar_wuyu,
          showImgPath: Details_wuyu,
          name: name_wuyu,
          race: '人类',
          ability: '能够使用魔法程度的能力',
          alias: '“东洋的西洋魔法师”、“奇妙的魔法使”、“普通的黑魔法少女”',
          profession: '魔法使',
          residence: '魔法森林',
          title: '雾雨魔理沙',
          JPtitle: 'きりさめ まりさ',
          risk: '',
          friendly: '',
          location: ''

        },
        {
          id: 3,
          path: avatar_baziyun,
          showMask: true,
          maskPath: maskAvatar_baziyun,
          showImgPath: Details_baziyun,
          name: name_baziyun,
          race: '妖怪',
          ability: '操纵境界程度的能力',
          alias: '“神隐的主犯”、“境界的妖怪”、“潜藏于境界边的妖怪”',
          profession: '',
          residence: '',
          title: '八云紫',
          JPtitle: 'やくも ゆかり',
          risk: '不明',
          friendly: '普通',
          location: '任何地点'

        },
        {
          id: 9,
          path: avatar_shiliuye,
          showMask: true,
          maskPath: maskAvatar_shiliuye,
          showImgPath: Details_shiliuye,
          name: name_shiliuye,
          race: '人类',
          ability: '操纵时间程度的能力',
          alias: '“红魔馆的女仆”、“完美潇洒的从者”、“危险的魔术师”',
          profession: '女仆',
          residence: '红魔馆',
          title: '十六夜咲夜',
          JPtitle: 'いざよい さくや',
          risk: '',
          friendly: '',
          location: ''

        },
        {
          id: 5,
          path: avatar_ailisi,
          showMask: true,
          maskPath: maskAvatar_ailisi,
          showImgPath: Details_ailisi,
          name: name_ailisi,
          race: '魔法师',
          ability: '操作人偶程度的能力',
          alias: '“七色的人偶师”、“操纵人偶的魔法使”',
          profession: '',
          residence: '',
          title: '爱丽丝·玛格特洛依德',
          JPtitle: 'アリス・マーガトロイド',
          risk: '低',
          friendly: '高',
          location: '任何地点'

        },
        {
          id: 6,
          path: avatar_lingxian,
          showMask: true,
          maskPath: maskAvatar_lingxian,
          showImgPath: Details_lingxian,
          name: name_lingxian,
          race: '月兔',
          ability: '操纵狂气程度的能力',
          alias: '“狂气的月兔”、“令视界摇晃的妖怪兔”、“地上的月兔”',
          profession: '',
          residence: '',
          title: '铃仙·优昙华院·因幡',
          JPtitle: 'れいせん・うどんげいん・イナバ',
          risk: '不明',
          friendly: '普通',
          location: '迷途竹林'
        }
      ],
      activeData: {

      }
    }
  },
  created() {
    this.initActiveData()
  },
  methods: {
    initActiveData() {
      this.assetsList = this.assetsList.sort((a, b) => a.id - b.id)
      this.activeData = this.assetsList[0]
      this.activeData.index = 0
    },
    clickRole(item, index) {
      this.activeData = item
      this.activeData.index = index
    },
    clickNext(e, type) {
      let name;
      if (e) {
        name = e.target.className
      }
      const index = this.activeData.index
      if (name === 'Previous' || type === 'Previous') {
        if (index == 0) return
        this.activeData = this.assetsList[index - 1]
        this.activeData.index = index - 1
      }
      if (name === 'next' || type === 'next') {
        if (index == 8) return
        this.activeData = this.assetsList[index + 1]
        this.activeData.index = index + 1
      }
    },
    swipeLeft() {
      this.clickNext(false, 'next')
    },
    swipeRight() {
      this.clickNext(false, 'Previous')
    }
  }

}
</script>

<style lang="scss" scoped>
.CharacterIntroduction {
  overflow: hidden;
  background: url('https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/135.png') no-repeat center;
  background-size: 100% 100%;
  width: 100%;
  .head {
      img {
        width: 100%;
      }
    }
    .body {
      .renwu {
        position: relative;
        img {
          width: 100%;
        }
        .name {
          position: absolute;
          bottom: 7px;
          left: 22px;
            width: 230px;
          img {
            width: 100%;
          }
        }
      }
      .info {
        position: relative;
        width: 100%;
        min-height: 180px;
        background: url('https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/591.png') no-repeat center;
        height: 180px;
        background-size: 100% 100%;
        padding-left: 65px;
        padding-bottom: 10px;

        .ul {
          padding-top: 5px;
          .li {
            margin-bottom: 2px;
            width: 100%;
            display: flex;
            .key {
              padding: 1px 8px;
              height: 20px;
              line-height: 20px;
              font-size: 12px;
              background-color: #811E22;
              color: #fff;
            }
            .value {
              width: 175px !important;
              padding-left: 10px;
              font-size: 12px;
            }
          }
        }
        .fenye {
          position: absolute;
          right: 20px;
          bottom: 10px;
          color: #8A909B;
          font-style: italic;
          .big {
            font-size: 20px;
          }
        }

        .Previous{
          content: '';
          width: 36px;
          height: 36px;
          background: url('https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/132.png') no-repeat center;
          background-size: 100% 100%;
          position: absolute;
          left: 2px;
          top: 50%;
          transform: translateY(-50%);
        }
        .next{
          content: '';
           width: 36px;
          height: 36px;
          background: url('https://thcdn.gggamedownload.com/source/mobileAssets/tanchuang/133.png') no-repeat center;
          background-size: 100% 100%;
          position: absolute;
          right:  2px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

.avatar {
  width: 100%;
  padding-top: 10px;
  .centerBox {
    // position: absolute;
    // left: 50%;
    // top: 0;
    // width: 330px;
    display: flex;
    flex-wrap: wrap;
  }
  .box {
    width: 63px;
    height: 63px;
    margin-left: 10px;
    display: flex;
    position: relative;
    img {
      width: 100%;
    }
    &:nth-child(6) {
      margin-left: 15px;
      img {
        position: absolute;
        transform: translateY(-50%) translateX(50%);
      }
    }
    &:nth-child(7) {
      margin-left: 10px;

      img {
        position: absolute;
        transform: translateY(-50%) translateX(50%);
      }
    }
    &:nth-child(8) {
      margin-left: 10px;

      img {
        position: absolute;
        transform: translateY(-50%) translateX(50%);
      }
    }
    &:nth-child(9) {
      margin-left: 10px;

      img {
        position: absolute;
        transform: translateY(-50%) translateX(50%);
      }
    }
  }
}

    }
}
</style>
